import { useQuery, UseQueryResult } from 'react-query';
import api from '~/services/api';
import { ControleAcessoProps, ResponsePlanoContratacaoProps } from '../types';

export const useControleAcesso = (): UseQueryResult<
  {
    value: number;
    label: string;
  }[],
  unknown
> => {
  return useQuery(
    'controleAcesso',
    async () => {
      const { data } = await api.get<
        ResponsePlanoContratacaoProps<ControleAcessoProps>
      >('/plano-contratacao/controle-acesso');

      if (data.success) {
        return data.data.map((opt) => ({
          value: opt.cod_controle,
          label: `${opt.cod_controle} - ${opt.des_controle}`,
        }));
      }

      throw new Error('Erro ao buscar os controles de acesso');
    },
    {
      refetchOnWindowFocus: true,
    },
  );
};
