export const displayInputValuesByName = (name: string): void => {
  const textInputs = document.querySelectorAll<HTMLInputElement>(
    `input[type="text"][name="${name}"]`,
  );

  if (textInputs.length === 0) return;

  textInputs.forEach((input) => {
    input.value = '';
    input.placeholder = 'Selecione...';
  });
};
