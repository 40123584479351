import styled from 'styled-components';

export const PlanoContainer = styled.section`
  width: 100%;
  height: 100%;
  padding: 1.25rem;

  .content-name {
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      width: 37.5rem;
      margin-right: 0.9375rem;
    }

    @media only screen and (max-width: 995px) {
      input {
        width: 31.25rem;
      }
    }

    @media only screen and (max-width: 770px) {
      input {
        width: 18.75rem;
      }
    }
  }
`;
