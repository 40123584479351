import React, { ChangeEvent, useCallback, useState } from 'react';
import { PlanoContainer } from './styles';
import { usePlanoContratacao } from './hooks/usePlanoContratacao';
import Search from '~/components/Search';
import FormDefault from '~/components/FormDefault';
import {
  InputSelect,
  InputText,
  InputTextArea,
} from '~/components/NovosInputs';
import ToggleDefault from '~/components/ToggleDefault';
import api from '~/services/api';
import { toast } from 'react-toastify';
import { GridRowParams } from '@material-ui/data-grid';
import { displayInputValuesByName } from './utils/displayInputValuesByName';

export const PlanoContratacaoContent: React.FC = () => {
  const [flgInativo, setFlgInativo] = useState(false);
  const [codSeqPlano, setCodSeqPlano] = useState(0);

  const {
    showSearch,
    handleShowSearch,
    isUpdate,
    handleUpdate,
    formTela,
    controleAcesso,
  } = usePlanoContratacao();

  const {
    register,
    setValue,
    control,
    reset,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = formTela;

  const onNewData = useCallback(
    (showsearch: boolean) => {
      ['des_nome', 'flg_inativo', 'des_plano', 'cod_controle'].forEach(
        (field) => clearErrors(field),
      );

      setValue('des_nome', '');
      setValue('flg_inativo', false);
      setValue('des_plano', '');

      setTimeout(() => {
        setValue('cod_controle', { value: null, label: '' });
        displayInputValuesByName('cod_controle');
      }, 500);

      handleShowSearch(showsearch);
      setFlgInativo(false);
      handleUpdate(false);
    },
    [clearErrors, handleShowSearch, handleUpdate, setValue],
  );

  const onRowClick = useCallback(
    (param: GridRowParams) => {
      const { row } = param;
      const { cod_seq_plano, des_nome, flg_inativo, des_plano, des_controle } =
        row;

      handleUpdate(true);
      handleShowSearch(false);

      setCodSeqPlano(cod_seq_plano);

      setValue('des_nome', des_nome);

      setValue('flg_inativo', flg_inativo);
      setFlgInativo(flg_inativo);

      setValue('des_plano', des_plano);

      const codControle = String(des_controle).split('-')[0];

      setValue('cod_controle', { value: codControle, label: des_controle });
    },
    [handleShowSearch, handleUpdate, setValue],
  );

  const API_ENDPOINTS = {
    update: (codSeq: number) => `/plano-contratacao/update/${codSeq}`,
    store: '/plano-contratacao/store',
  };

  const handleApiResponse = (response: any, successCallback: any) => {
    if (response.data.success) {
      successCallback();
      toast.success(response.data.message);
    } else {
      toast.error('Ocorreu um erro. Por favor, tente novamente.');
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    let response;

    if (isUpdate) {
      response = await api.put(API_ENDPOINTS.update(codSeqPlano), data);
      handleApiResponse(response, () => {
        reset();
        setFlgInativo(false);
        handleShowSearch(true);
        handleUpdate(false);
      });
    } else {
      response = await api.post(API_ENDPOINTS.store, data);
      handleApiResponse(response, onNewData);
    }
  });

  return (
    <PlanoContainer>
      {showSearch && (
        <Search
          codTela={304}
          newData={() => onNewData(false)}
          onRowClick={onRowClick}
        />
      )}
      {!showSearch && (
        <FormDefault
          codTela={304}
          title="Plano de Contratação"
          onSave={onSubmit}
          onCancel={() => {
            reset();
            setFlgInativo(false);
            handleShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNewData(false)}
          onDelete={async () => {
            const { data } = await api.delete(
              `/plano-contratacao/delete/${codSeqPlano}`,
            );

            if (data.success) {
              toast.success(data.message);
              onNewData(true);
            }
          }}
          onClearFields={() => {
            onNewData(false);
          }}
          onReturnSearch={() => handleShowSearch(true)}
        >
          <div className="content">
            <div className="content-name">
              <InputText
                label="Nome"
                placeholder="Informe o nome"
                register={register}
                name="des_nome"
                isError={!!errors.des_nome}
                maxLength={100}
                disabled={false}
                autoComplete="off"
                toLowerCase={false}
                onInput={(ev: ChangeEvent<HTMLInputElement>) => {
                  setValue('des_nome', ev.target.value);
                  clearErrors('des_nome');
                }}
              />

              <ToggleDefault
                labelText="Inativo?"
                setChecked={flgInativo}
                onSwitch={() => {
                  setValue('flg_inativo', !flgInativo);
                  setFlgInativo(!flgInativo);
                }}
              />
            </div>
            <InputTextArea
              maxLength={1000}
              label="Descrição"
              placeholder="Informe a descrição"
              name="des_plano"
              register={register}
              isError={false}
              control={control}
              rows={4}
              resize={false}
            />
            <InputSelect
              label="Controle de Acesso"
              placeholder="Selecione..."
              name="cod_controle"
              register={register}
              isError={!!errors.cod_controle}
              control={control}
              options={controleAcesso}
              disabled={false}
              changeSelected={(selected) => {
                setValue('cod_controle', selected);
                clearErrors('cod_controle');
              }}
            />
          </div>
        </FormDefault>
      )}
    </PlanoContainer>
  );
};
