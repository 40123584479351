import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  PlanoContratacaoContextData,
  UseFormPlanoContractProps,
} from '../types';
import { useForm } from 'react-hook-form';
import { planoSchema } from '../utils/plano-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';
import { useControleAcesso } from '../hooks/useControleAcesso';

export const PlanoContratacaoContext = createContext(
  {} as PlanoContratacaoContextData,
);

export const PlanoContratacaoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showSearch, setShowSearch] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [controleAcesso, setControleAcesso] = useState<SelectType[]>([]);

  const {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(planoSchema),
  });

  const { data: controleAcessoData } = useControleAcesso();

  const handleShowSearch = useCallback((show: boolean) => {
    setShowSearch(show);
  }, []);

  const handleUpdate = useCallback((update: boolean) => {
    setIsUpdate(update);
  }, []);

  useEffect(() => {
    if (controleAcessoData) setControleAcesso(controleAcessoData);
    else setControleAcesso([]);
  }, [controleAcessoData]);

  const formTela: UseFormPlanoContractProps = {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  };

  return (
    <PlanoContratacaoContext.Provider
      value={{
        isUpdate,
        showSearch,
        controleAcesso,
        handleShowSearch,
        handleUpdate,
        formTela,
      }}
    >
      {children}
    </PlanoContratacaoContext.Provider>
  );
};
