import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .btn-processar {
    background-color: #57069e;
    outline: none;
    box-shadow: none;
    border-color: none;
    transition: all ease-in-out 0.2s;
    &:hover {
      opacity: 0.91;
    }
  }

  .form-label {
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
    font-weight: 600;
    line-height: 1.3333333333333333;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    margin-bottom: 4px;
  }

  @media screen and (max-width: 767px) {
    .containerTitle {
      max-width: 200px;
    }
    .containerChild {
      max-width: 200px !important;
      padding-left: 0px !important;
    }
  }
`;

export const TableContainer = styled.div`
  height: 100%;
  height: 500px;

  .MuiDataGrid-cell {
    font-size: 13px;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  gap: 4px;
  min-height: 42px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  max-width: 300px;
  width: 100%;

  .file-item-close {
    align-self: start;
    font-weight: bold;
    color: red;
    font-size: 1.5rem;
    transition: 0.2ms;

    &:hover {
      opacity: 0.75;
      cursor: pointer;
    }
  }
`;

export const InputFileContainer = styled.div`
  display: none;
`;

export const Table = styled.div`
  .table-exibition {
    .itensTable {
      svg {
        font-size: 24px;
      }
    }

    .td-style {
      white-space: nowrap;
    }
    .editado {
      color: #48778c !important;
      background: #dcfcfb !important;
    }

    .table tbody {
      border: 1px solid #dee2e6 !important;
    }
    .table tbody tr td {
      color: #757575;
      width: 20%;
      border-left: 1px solid #dee2e6;
    }
    .table tbody tr td input {
      width: 100%;
      height: 100%;
      border: none;
      box-sizing: border-box;
      &:disabled {
        border: none !important;
        background-color: transparent !important;
      }
    }
    .table thead tr th {
      width: 20%;
      padding-left: 0px;
      border: none;
      font-size: 0.8571428571428571em;
      font-style: inherit;
      color: #8850bf;
      font-weight: 600 !important;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }
  }
`;
