import * as Yup from 'yup';

export const planoSchema = Yup.object().shape({
  des_nome: Yup.string()
    .required('O campo des_nome é obrigatório.')
    .min(1, 'O campo des_nome deve ter no mínimo 1 caractere.')
    .max(100, 'O campo des_nome deve ter no máximo 100 caracteres.'),

  des_plano: Yup.string()
    .max(1000, 'O campo des_plano deve ter no máximo 1000 caracteres.')
    .nullable(),

  cod_controle: Yup.object()
    .shape({
      value: Yup.number().required(
        'O campo value de cod_controle é obrigatório.',
      ),
      label: Yup.string().required(
        'O campo label de cod_controle é obrigatório.',
      ),
    })
    .required('O campo cod_controle é obrigatório.'),

  flg_inativo: Yup.boolean().default(false).nullable(),
});
