import React from 'react';
import { PlanoContratacaoContent } from './PlanoContratacaoContent';
import { PlanoContratacaoProvider } from './contexts/PlanoContratacaoContext';

const PlanoContratacao: React.FC = () => {
  return (
    <PlanoContratacaoProvider>
      <PlanoContratacaoContent />
    </PlanoContratacaoProvider>
  );
};

export default PlanoContratacao;
